<template>
  <v-autocomplete
    dense
    outlined
    no-filter
    hide-selected
    persistent-hint
    item-text="name"
    class="rounded-lg body-2"
    item-value="distribution_point_uid"
    label="Distribution point *"
    v-model="distributionPointUid"
    :items="distributionPoints.data"
    :search-input.sync="distributionPointSearch"
    :hint="errors.get('distribution_point_uid')"
    :error="errors.has('distribution_point_uid')"
    @input="$emit('clear', 'distribution_point_uid')"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title 
          class="body-2"
          v-text="item.name"
        ></v-list-item-title>

        <v-list-item-subtitle 
          class="caption"
          v-text="item.merchant.name"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    errors: {
      type: Object,
      required: true,
    }
  },

  data: () => ({
    distributionPointSearch: '',
  }),

  watch: {
    distributionPointSearch (n, o) {
      const selectedDistributionPoint = this.distributionPoints.data?.find((point) => {
        return point.distribution_point_uid === this.distributionPointUid
      })

      if (n && n.length > 1 && selectedDistributionPoint?.name !== n) {
        this.searchDistributionPoint(n)
      }

      if (!n && o.length) {
        this.loadDistributionPoints()
      }
    },
  },

  computed: {
    ...mapGetters({
      distributionPoints: 'getDistributionPoints',
    }),

    distributionPointUid: {
      get () {
        return this.$store.getters['retail/getDistributionPointUid']
      },

      set (value) {
        this.$store.dispatch('retail/setDistributionPointUid', value)
      }
    }
  },

  methods: {
    ...mapActions([
      'setDistributionPoints',
    ]),

    loadDistributionPoints (search = null) {
      return this.setDistributionPoints({
        params: {
          ...(search && { search })
        }
      })
    },

    searchDistributionPoint: _.debounce(function(search) {
      this.loadDistributionPoints(search)
    }, 700),
  },

  mounted () {
    this.loadDistributionPoints()
  }
}
</script>